import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
// import pdf1 from 'assests/Pdf/Oly22-23/Mathematic_olympiad.pdf';
// import pdf1 from 'assests/Pdf/Oly23-24/SOF achievers 2023-24.pdf';
import pdf2 from 'assests/Pdf/CCA/NPS_YESHWANTHPUR-STATE_RESULT.pdf';
import pdf3 from 'assests/Pdf/CCA/SCIENCE_OLYPIAD_FOUNDATION_2023-24.pdf';
import pdf4 from 'assests/Pdf/CCA/SILVER_ZONE_INTERNATIONAL_OLYMPIAD_MEDAL.pdf';
import pdf5 from 'assests/Pdf/CCA/TERI_Results_2023_24.pdf';
import pdf6 from 'assests/Pdf/CCA/UNIFIED_COUNCIL_OLYMPIADS_NSTSE_2023_24.pdf';
import pdf7 from 'assests/Pdf/CCA/All_india_general_knowledge_examination_2023.pdf';






import Container from 'components/Container';
import { Button } from '@mui/material';


const mock = [
  {
    name: '',
    title: 'SPELL BEE STATE LEVEL RESULTS',
    
    href:pdf2,
  },
  {
    name: '',
    title: 'TERI RESULTS',
    
    href:pdf5,
  },
  
  {
    name: '',
    title: 'SCIENCE OLYMPIAD FOUNDATION',
    
    href:pdf3,
  },

  {
    name: '',
    title: 'SILVER ZONE- INTERNATIONAL OLYMPIAD MEDAL',
    href:pdf4,
    },
    
    {
      name: '',
      title: 'UNIFIED COUNCIL OLYMPIADS-NSTSE',
      href:pdf6,
  },
  {
    name: '',
    title: 'ALL INDIA GENERAL KNOWLEDGE EXAMINATION - 2023',
    href:pdf7,
}
];

const Oly23 = () => {
  const theme = useTheme();

  return (
  
    <Container>
      
      <Box>
        <Box >
          <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            gutterBottom
            color={'text.secondary'}
            align={'center'}
            fontWeight={700}
          >
           OLYMPIAD 2023-24
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                width={0.9}
                height={0.9}
                component={Card}
                boxShadow={0}
                variant={'outlined'}
                bgcolor={'alternate.main'}
              >
                <CardContent sx={{ padding: 3 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                  
                    <Button 
                    href={item.href} target="_blank">
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.name}
                      secondary={item.title}
                      primaryTypographyProps={{
                        variant: 'h6',
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{ variant: 'subtitle1' }}
                    />
                    </Button>
                  </ListItem>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      
    </Container>

  );
};

export default Oly23;