import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import pdf1 from 'assests/Pdf/Olympiad/Oly2022/1.pdf';
import pdf2 from 'assests/Pdf/Olympiad/Oly2022/4.pdf';
import pdf4 from 'assests/Pdf/Olympiad/Oly2022/5.pdf';
import pdf3 from 'assests/Pdf/Olympiad/Oly2022/6.pdf';


import Container from 'components/Container';
import { Button } from '@mui/material';


const mock = [
  {
    name: '',
    title: ' OLYMPIAD OF MATHEMATICS',
    
    href:pdf1,
  },
  {
    name: '',
    title: 'SOF INTERNATIONAL MATHEMATICS OLYMPIAD',
   
    href:pdf2,
  },
  {
    name: '',
    title: ' OLYMPAID UNIFIED COUNCIL- NSTSE',
    
    href:pdf3,
  },
  {
    name: '',
    title: ' SCIENCE OLYMPIAD FOUNDATION 2021-22',
   
    href:pdf4,
  },


];

const Oly = () => {
  const theme = useTheme();

  return (
  
    <Container>
      
      
        <Box>
          <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            gutterBottom
            color={'text.secondary'}
            align={'center'}
            fontWeight={700}
          >
           OLYMPIAD 2021-22
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                width={0.9}
                height={0.9}
                component={Card}
                boxShadow={0}
                variant={'outlined'}
                bgcolor={'alternate.main'}
              >
                <CardContent sx={{ padding: 3 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                  
                    <Button 
                    href={item.href} target="_blank">
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.name}
                      secondary={item.title}
                      primaryTypographyProps={{
                        variant: 'h6',
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{ variant: 'subtitle1' }}
                    />
                    </Button>
                  </ListItem>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      
      
    </Container>

  );
};

export default Oly;

